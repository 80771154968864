.back-button__wrapper {
  display: flex;
  align-items: center;
}

.back-button__icon {
  color: #24629e;
  cursor: pointer;
}

.back-button__user_id {
  margin: 0;
  margin-left: 10px;

  @media (max-width: 767px) {
    color: #474f54;
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
  }
}

@keyframes fade {
  from { opacity: 0; }
  to { opacity: 1; }
}


.loader-svg {
  height: 400px;
}

.p {
	animation: rotation 2s infinite ease-in-out;
  animation-delay: 0.2s;
}
.r {
	animation: rotation 2s infinite ease-in-out;
  animation-delay: 0.4s;
}
.o {
	animation: rotation 2s infinite ease-in-out;
  animation-delay: 0.6s;
}
.m {
	animation: rotation 2s infinite ease-in-out;
  animation-delay: 0.8s;
}
.p2 {
	animation: rotation 2s infinite ease-in-out;
  animation-delay: 1s;
}
.t {
	animation: rotation 2s infinite ease-in-out;
  animation-delay: 1.2s;
}
.l {
	animation: rotation 2s infinite ease-in-out;
  animation-delay: 1.4s;
}
.y {
	animation: rotation 2s infinite ease-in-out;
  animation-delay: 1.6s;
}

@keyframes rotation {
  0%  {transform: translate(0px, 0px);}
  10% {transform: translate(0px, -20px);}
  20% {transform: translate(0px, 0px);}
  100% {transform: translate(0px, 0px);}
}
@import "./variables.scss";
$header-size: 20%;

.mg-bottom-0px {
  margin-bottom: 0;
}

.flex-2 {
  flex: 2;
}

.justify-flex-end {
  justify-content: flex-end;
}

.align-flex-center {
  align-items: center;
}

.login-view-container {
  display: flex;

  @media (max-width: 767px) {
    margin: 0px;
    padding: 0px;
    width: 100%;
    max-width: 100%;
  }
}

.login-forms-wrapper {
  background-color: #fff;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow: auto;

  @media (max-width: 767px) {
    padding: 0px;

    & p {
      &:not(.error) {
        color: #59646a;
        font-weight: 600;
      }
      font-family: $font-family-primary;
      font-size: 16px;
      line-height: 22px;
    }
  }

  @media (max-width: 425px) {
    background-position: 0% 65%;
  }
  @media (max-width: 575px) {
    height: 100%;
    min-height: auto;
    position: fixed;
    width: calc(100% - 30px);
  }
}

.login-forms-wrapper-no-bg {
  background-image: none;
}

.prom-logout-header {
  flex: 1 0 100px;

  @media (min-width: 768px) {
    display: none;
  }

  @media (max-width: 767px) {
    padding-top: 30px;
    flex: 0 0 130px;
    position: fixed;
    background: white;
    padding-bottom: 30px;
    width: 100%;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
    left: 0;
    padding: 16px;
    height: 65px;
  }
}

.prom-login-header {
  flex: 1 20 90px;

  @media (min-width: 768px) {
    display: none;
  }

  @media (max-width: 767px) {
    padding-top: 30px;
    flex: 0 0 130px;
  }
}

.prom-login-header,
.prom-logout-header {
  @media (max-width: 767px) {
    & > img {
      width: 200px;
    }
  }
}

.flyer-content,
.prom-login-content {
  flex: 1 30 100%;

  #{&}__title {
    color: #101214;
    margin-bottom: 0px;
    flex: 1 1 auto;
    @media (max-width: 767px) {
      color: #14171a;
      font-family: $font-family-secondary;
      font-size: 24px;
      font-weight: 600;
      line-height: 33px;
    }
  }

  #{&}__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    a#forgot-password,
    a#back-button,
    a#resend-code {
      margin-right: 10px;
    }

    @media (min-width: 768px) {
      & > a#signup {
        margin-right: auto;
      }
    }

    @media (max-width: 767px) {
      margin-top: 20px;
      flex-direction: column-reverse;
      align-items: stretch;

      a#forgot-password,
      a#back-button,
      a#resend-code {
        margin-right: 0;
      }
      & > a {
        text-align: center;
        margin-top: 15px;
      }
    }
  }

  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
    flex: 1 1;
  }
}

.prom-signup-detail-content {
  flex: 1 30 100%;
  min-height: 450px;

  @at-root #{&}__title {
    color: #101214;
    margin-bottom: 0px;
  }

  @at-root #{&}__actions {
    display: flex;
    align-items: center;
  }

  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
    flex: 1 1 825px;
  }
}

.prom-login-forms-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.prom-login-content__more-info {
  display: block;

  @media (max-width: 767px) {
    display: none;
  }
}

.prom-login-content {
  position: relative;
  overflow-x: hidden;
  justify-content: center;

  @media (max-width: 767px) {
    margin-top: 65px;
  }
}

.flyer-content,
.flyer-content-gravidity {
  padding: 11px;
  align-items: flex-start;
  padding-top: 20px;
}

.flyer-content {
  background: url("../images/prom-login-life-line.svg") no-repeat;
}

.prom-login-footer,
.prom-login-footer {
  flex: 3 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  p {
    margin: 0;
    font-size: 12px;
  }

  @at-root #{&}__copy {
    color: #88949d;
  }

  @at-root #{&}__link {
    font-size: 12px;
  }

  @media (min-width: 768px) {
    flex: 1 1 50px;
    margin-top: 10px;
  }
}

.flyer-wrapper,
.flyer-wrapper-gravidity {
  min-height: 100vh;
  display: none;
  background: #0e416c;
  color: #fff;
  padding: 20px 0px;

  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
  }
}

.flyer-wrapper-gravidity {
  background: url("../images/img-bg-pregnancy.jpg") no-repeat;
}

.flyer-content__title {
  color: #fff;
  font-weight: 400;
  margin-bottom: 18px;
}

.flyer-content__info {
  height: 18px;
  width: 237px;
  color: #88949d;
  font-size: 14px;
  font-family: $font-family-primary;
  line-height: 18px;
  letter-spacing: 0;
}

/* new login */
.no-padding {
  padding-left: 0px;
  padding-right: 0px;
  max-width: 100%;
}

.login-background {
  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: linear-gradient(
      to bottom,
      white 70%,
      white 70%,
      #e8edf2 30%,
      #e8edf2 30%
    );
    background-position: bottom;
  }

  @media (max-width: 767px) {
    width: 0%;
    display: none;
  }
}

.login-flyer-wrapper {
  color: #fff;
  padding: 30px 0px 30px 37px;

  @media (min-width: 768px) {
    width: 100%;
    background: url("../images/Group_4.svg") no-repeat left top;
    background-position: right;
    background-position-y: 65%;
    height: 100%;
  }

  @media (max-width: 767px) {
    width: 0%;
  }
}

.clinical-trials-container {
  height: 100%;
  overflow-y: auto;
  padding: 20px 0px;
}

@import '../../assets/css/variables.scss';

html {
  background-color: #fff!important;
}
body {
  font-family: $font-family-primary; 
  font-size: 16px; 
  margin: 0; 
  padding: 0;
}
h1 {
  font-family: $font-family-secondary;
  font-size: 30px;
  color:#474F54;
  margin-bottom: 40px;
}

img {max-width: 100%;}

.container-http {    
  text-align: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
}
.logo {margin-bottom: 40px;}
.imgError {margin-bottom: 20px;}
